import React from "react";
import { Navbar, Nav, Form, FormControl, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, animateScroll as scroll } from "react-scroll";

// Deve criar um scroll para uma so pagina falando sobre o projeto

function Home() {
  return (
    <div>
      <Navbar bg="dark" variant="dark">
        <Navbar.Brand href="#home">Navbar</Navbar.Brand>
        <Nav className="mr-auto">
          <Nav.Link href="#home">Home</Nav.Link>
          <Nav.Link href="#sobre">Sobre</Nav.Link>
          <Nav.Link href="#servicos">Servicos</Nav.Link>
          <Nav.Link href="#contato">Contato</Nav.Link>
        </Nav>
      </Navbar>
      <div id="home">
        <h1>Home</h1>
      </div>
      <div id="sobre">
        <h1>Sobre</h1>
      </div>
      <div id="servicos">
        <h1>Servicos</h1>
      </div>
      <div id="contato">
        <h1>Contato</h1>
      </div>
    </div>
  );
}

export default Home;
