import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import React, { useEffect, useState } from "react";
import SignUp from "../pages/SignUp";
import SignIn from "../pages/SignIn";
import ContrucaoService from "../pages/ContrucaoService";
import { useAuth } from "../contexts/auth";
import ListarConstrucao from "../pages/ListarConstrucao";
import Home from "../pages/Home";

const PrivateRouter = ({ element, redirect, isLogged }) => {
  return <>{isLogged ? element : <Navigate to={redirect} />}</>;
};

const AppRoutes = () => {
  const { token } = useAuth();

  const [isLogged, setIslogged] = useState(!!token);

  useEffect(() => {
    setIslogged(!!token);
  }, [token]);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/signin" element={<SignIn />} isLogged={isLogged} />

        <Route
          path="/construcaoService"
          element={
            <PrivateRouter
              element={<ContrucaoService />}
              redirect="/signin"
              isLogged={isLogged}
            />
          }
        />

        <Route
          path="/listarConstrucao"
          element={
            <PrivateRouter
              element={<ListarConstrucao />}
              redirect="/signin"
              isLogged={isLogged}
            />
          }
        />

        <Route path="/signup" element={<SignUp />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
